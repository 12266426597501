import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'
import 'react-phone-number-input/style.css'
import { notifyError, notifySuccess } from '../../helper/Alert';
import { confirmPasswordValidation, emailValidation, nameValidation } from '../../helper/validation.helper';
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
// import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';
import { addNewMember } from '../../controller/customers.controller';

class AddMemberModal extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            qLoader: false,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            mobile:'',
            phoneError: '',
            password: '',
            confirmPassword: '',
            address: '',
            errors: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                address: ''
            },
            validtions: {
                firstName: false,
                lastName: false,
                email: false,
                password: false,
                confirmPassword: false,
                address: false
            }
        }
    }

    componentDidMount() {
        this._unmounted = true;
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps) {
        const { show } = this.props;
        if (show !== prevProps.show) {
            if (show) {
                if (this._unmounted) {
                    this.setState({
                        qLoader: false,
                        firstName: '',
                        lastName: '',
                        email: '',
                        countryCode: '',
                        phone: '',
                        phoneError: '',
                        password: '',
                        confirmPassword: '',
                        address: '',
                        errors: {
                            firstName: '',
                            lastName: '',
                            email: '',
                            password: '',
                            confirmPassword: '',
                            address: ''
                        },
                        validtions: {
                            firstName: false,
                            lastName: false,
                            email: false,
                            password: false,
                            confirmPassword: false,
                            address: false
                        }
                    })
                }
            }
        }
    }

    inputHandler = (e) => {
        const { name, value } = e.target;
        if (this._unmounted) {
            this.setState({
                [name]: value
            }, () => this.formValidation(name, value))
        }
    }

    formValidation = (name, value) => {
        let error = this.state.errors;
        let valid = this.state.validtions;
        switch (name) {
            case 'firstName':
                const fres = nameValidation(value);
                valid.firstName = fres.status;
                error.firstName = fres.message;
                break;
            case 'lastName':
                const lres = nameValidation(value);
                valid.lastName = lres.status;
                error.lastName = lres.message;
                break;
            case 'email':
                const eres = emailValidation(value);
                valid.email = eres.status;
                error.email = eres.message;
                break;
            case 'password':
                if (value.length > 0) {
                    if (value.length >= 8) {
                        error.password = '';
                        valid.password = true;
                    } else {
                        error.password = 'Password at least 8 characters';
                        valid.password = false;
                    }
                } else {
                    error.password = 'Required.';
                    valid.password = false;
                }
                break;
            case 'confirmPassword':
                const cres = confirmPasswordValidation(this.state.password, value);
                valid.confirmPassword = cres.status;
                error.confirmPassword = cres.message;
                break;
            case 'address':
                if (value.length > 0) {
                    error.address = '';
                    valid.address = true;
                } else {
                    error.address = 'Required.';
                    valid.address = false;
                }
                break;
            default:
                break;
        }

        this.setState({
            errors: error,
            validtions: valid
        })
    }

    selectCountry = (e) => {
        // console.log(e);
        this.setState({
            phone: '',
            phoneError: ''
        })
    }

    phoneNumberHandler = (number) => {
        let error = '';
        let countryCode = '';
        let phoneNumber = '';
        if (number) {

            if (isValidPhoneNumber(number) === false) {
                error = 'Invalid phone number';
                countryCode = '';
                phoneNumber = number
            } else {
                const coun = parsePhoneNumber(number);
                const code = getCountryCallingCode(coun.country);
                // console.log(coun) 
                error = '';
                countryCode = code;
                phoneNumber = `${code}${coun.nationalNumber}`
            }

        } else {
            error = "Phone number required";
            countryCode = '';
            phoneNumber = number
        }
        if (this._unmounted) {
            this.setState({
                mobile:number,
                phone: phoneNumber,
                countryCode: countryCode,
                phoneError: error
            })
        }
    }

    formSubmit = async (e) => {
        e.preventDefault();
        const { firstName, lastName, email, password, confirmPassword, phone, phoneError, address, countryCode, validtions } = this.state;
        if (!validtions.firstName) {
            return notifyError('First Name must be required!')
        }
        if (!validtions.lastName) {
            return notifyError('Last Name must be required!')
        }
        if (!validtions.email) {
            return notifyError('Last Name must be required!')
        }
        if (phoneError !== '') {
            return notifyError(phoneError)
        }
        if (!validtions.password) {
            return notifyError('Password must be required!')
        }
        if (!validtions.confirmPassword) {
            return notifyError('Confirm password must be required!')
        }
        if (!validtions.address) {
            return notifyError('Address must be required!')
        }

        const { userData } = this.props;

        const data = {
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            confirmPassword,
            phone_number: `${phone}`,
            address,
            user_id: userData.user_id,
            storage: userData.storage,
            plan_id: userData.plan_id,
            org_id: userData.org_id
        }

        if (this._unmounted) {
            this.setState({
                qLoader: true
            });
        }

        const res = await addNewMember(data)
        // console.log(res);
        if (res.status) {

            if (this._unmounted) {
                this.setState({
                    qLoader: false
                });
            }
            this.props.onSubmit(res.data)
            this.props.onHide();
            notifySuccess(res.message);

        } else {
            if (this._unmounted) {
                this.setState({
                    qLoader: false
                });
            }
            notifyError(res.message);
        }
    }


    render() {

        const { errors, phoneError, firstName, lastName, email, mobile, password, confirmPassword, address } = this.state;

        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header>
                    <Modal.Title>Add Member</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">

                    <form action="/" method="POST" onSubmit={this.formSubmit}>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group mb-4 position-relative">
                                    <label className="form-label">First Name</label>
                                    <input type="text" name="firstName" className={errors.firstName !== '' ? "form-control error" : "form-control"} placeholder="Enter first name" onChange={this.inputHandler} value={firstName} />
                                    {errors.firstName !== '' ? <p className="text-danger position-absolute">{errors.firstName}</p> : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4 position-relative">
                                    <label className="form-label">Last Name</label>
                                    <input type="text" name="lastName" className={errors.lastName !== '' ? "form-control error" : "form-control"} placeholder="Enter last name" onChange={this.inputHandler} value={lastName} />
                                    {errors.lastName !== '' ? <p className="text-danger position-absolute">{errors.lastName}</p> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group mb-4 position-relative">
                                    <label className="form-label">Email</label>
                                    <input type="text" name="email" className={errors.email !== '' ? "form-control error" : "form-control"} placeholder="Enter email" onChange={this.inputHandler} value={email} />
                                    {errors.email !== '' ? <p className="text-danger position-absolute">{errors.email}</p> : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4 position-relative">
                                    <label className="form-label">Phone Number</label>
                                    <PhoneInput
                                        className={`phone-input ${phoneError !== ''?'error':''}`}
                                        flags={flags}
                                        // containerClassName={`intl-tel-input phone_container`}
                                        // inputClassName="form-control"
                                        placeholder="Enter Mobile number"
                                        onChange={this.phoneNumberHandler}
                                        // onPhoneNumberChange={this.phoneHandler}
                                        value={mobile}
                                    />
                                    {/* <IntlTelInput
                                        defaultCountry="in"
                                        containerClassName={phoneError !== '' ? "intl-tel-input phone-input error" : "intl-tel-input phone-input"}
                                        customPlaceholder="Phone number"
                                        onSelectFlag={this.selectCountry}
                                        onPhoneNumberChange={this.phoneNumberHandler}
                                        value={phone}
                                    /> */}
                                    {phoneError !== '' ? <p className="text-danger position-absolute">{phoneError}</p> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group mb-4 position-relative">
                                    <label className="form-label">Password</label>
                                    <input type="password" name="password" className={errors.password !== '' ? "form-control error" : "form-control"} placeholder="Enter password (At least 8 characters)" onChange={this.inputHandler} value={password} />
                                    {errors.password !== '' ? <p className="text-danger position-absolute">{errors.password}</p> : null}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group mb-4 position-relative">
                                    <label className="form-label">confirm Password</label>
                                    <input type="password" name="confirmPassword" className={errors.confirmPassword !== '' ? "form-control error" : "form-control"} placeholder="Enter confirm password" onChange={this.inputHandler} value={confirmPassword} />
                                    {errors.confirmPassword !== '' ? <p className="text-danger position-absolute">{errors.confirmPassword}</p> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group mb-4 position-relative">
                                    <label className="form-label">Address</label>
                                    <input type="text" name="address" className={errors.address !== '' ? "form-control error" : "form-control"} placeholder="Enter address" onChange={this.inputHandler} value={address} />
                                    {errors.address !== '' ? <p className="text-danger position-absolute">{errors.address}</p> : null}
                                </div>
                            </div>
                        </div>

                        <div className="form-group d-flex justify-content-end border-top pt-4">
                            <button type="button" className="btn btn-secondary border-0 me-2" onClick={this.props.onHide}>Close</button>
                            <button type="submit" aria-label="Submit" className="btn btn-success" disabled={this.state.qLoader}>{this.state.qLoader ? <SmallLoaderComponent color="white" /> : 'Submit'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}


export default withRouter(connect(null)(AddMemberModal));