import React, { Component } from 'react';
// import AvtarImg from '../assets/img/avatars/avatar.jpg'
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FiSettings, FiUser, FiPower } from "react-icons/fi";
// import { sideBarToggle } from '../../redux/actions/admin/layoutAction';
import * as ACTION_TYPES from '../redux/actions/admin/type'
import { jwtDecode } from '../helper/jwt.helper';

class TheHeader extends Component {

	_unmounted = false;

	constructor(props){
		super(props);
		this.state={
			shotName:'',
			name:''
		}
	}

	componentDidMount(){
		this._unmounted = true;
		const adminToken = localStorage.getItem('adminToken');
		if(adminToken){
			const decode = jwtDecode(adminToken);
			if(decode && decode.email){
				
				const fname = decode.firstName;
				const lname = decode.lastName;
				const firstcharactor = fname.charAt(0);
				const lastcharactor = lname.charAt(0)
				if(this._unmounted){
					// console.log(fname);
					this.setState({
						shotName:`${firstcharactor}${lastcharactor}`,
						name:`${fname} ${lname}`
					})
				}
			}
		}
	}

	componentWillUnmount(){
		this._unmounted = false;
	}

	logoutAdmin = (e) => {
		e.preventDefault();
		localStorage.removeItem('adminToken');
		this.props.history.push('/admin');
	}

	sidebarToggle = () => {
		const { isSidebar, toggleSidebar } = this.props;
		toggleSidebar(!isSidebar);
	}

	render() {

		const { shotName, name } = this.state;
		

		return (
			<nav className="navbar navbar-expand navbar-light navbar-bg">
				<button type="button" className="sidebar-toggle js-sidebar-toggle bg-transparent border-0 p-0" onClick={this.sidebarToggle}>
					<i className="hamburger align-self-center"></i>
				</button>

				<div className="navbar-collapse collapse">
					<ul className="navbar-nav navbar-align">

						<li className="nav-item dropdown">
							{/* <button type="button" className="nav-icon dropdown-toggle d-inline-block d-sm-none bg-transparent border-0"
								data-bs-toggle="dropdown">
								<FiSettings />
							</button> */}
							<Dropdown id="basic-nav-dropdown">
								<Dropdown.Toggle as="button" className="nav-link dropdown-toggle d-none d-sm-inline-block d-sm-flex align-items-center bg-transparent border-0 pt-0 pb-0" id="dropdown-autoclose-inside">
									{/* <img src={AvtarImg} className="avatar img-fluid rounded me-1" alt="Charles Hall" /> */}
									<i className="avatar-text-wrap me-1">{shotName}</i>
									<span className="text-dark font-manul">{name}</span>
								</Dropdown.Toggle>
								<Dropdown.Toggle as="button" className="nav-icon dropdown-toggle d-inline-block d-sm-none bg-transparent border-0" id="dropdown-autoclose-inside">
									<FiSettings />
								</Dropdown.Toggle>
								<Dropdown.Menu bsPrefix="dropdown-menu dropdown-menu-end drop_menu">
									<Link className="dropdown-item" to="/admin/profile">
										<FiUser />
										Profile
									</Link>
									<div className="dropdown-divider"></div>
									<Link to="/admin" className="dropdown-item" onClick={this.logoutAdmin}><FiPower /> Log out</Link>
								</Dropdown.Menu>
								{/* <NavDropdown.Divider /> */}
								{/* <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
							</Dropdown>
							{/* <button type="button" className="nav-link dropdown-toggle d-none d-sm-inline-block bg-transparent border-0"
								data-bs-toggle="dropdown">
								<img src={AvtarImg} className="avatar img-fluid rounded me-1"
									alt="Charles Hall" /> <span className="text-dark">Charles Hall</span>
							</button>
							<div className="dropdown-menu dropdown-menu-end">
								<Link className="dropdown-item" to="/admin/change-password">
									<FiUnlock />
									Change Password
								</Link>
								<div className="dropdown-divider"></div>
								<Link to="/admin" className="dropdown-item" onClick={this.logoutAdmin}>Log out</Link>
							</div> */}
						</li>
					</ul>
				</div>
			</nav>
		)
	}
}

const mapStateToProps = state => ({
	isSidebar: state.layout.openSideBar
})

const mapDispatchToProps = dispatch => ({
	toggleSidebar: (bool) => dispatch({ type: ACTION_TYPES.SIDEBAR_TOGGLE, payload: bool })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TheHeader))