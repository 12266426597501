import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { notifyError, notifySuccess } from '../../helper/Alert';
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
import { updateSuperAdmin } from '../../controller/users.controller';
import * as Yup from 'yup';
import { Formik } from 'formik';

class UpdateSuperAdminModal extends Component {

    _unmounted = false;
    constructor(props) {
        super(props);
        this.state = {
            qLoader: false,
            userData: null,
            id: '',
            form: {
                firstName: '',
                lastName: '',
                email: '',
                // password: '',
                status: true
            }
        }
    }

    componentDidMount() {
        this._unmounted = true;
        const { userData } = this.props;
        if (userData) {
            if (this._unmounted) {
                this.setState({
                    id: userData.id,
                    // form: {
                    //     ...this.state.form,
                    //     firstName: userData.firstName,
                    //     lastName: userData.lastName,
                    //     email: userData.email,
                    //     // password: userData.password,
                    //     status: parseInt(userData.status) === 1 ? true : false
                    // }
                })
            }
        }
        
    }

    componentWillUnmount() {
        this._unmounted = false;
    }


    formSubmit = async (e, actions) => {

        const { firstName, lastName, email, status } = e;

        if (this._unmounted) {
            this.setState({
                qLoader: true
            });
        }

        const res = await updateSuperAdmin({ id: this.state.id, firstName, lastName, email, status })
        if (res.status) {
            actions.resetForm({
                e: {
                    firstName: '',
                    lastName: '',
                    email: "",
                    status: true
                }
            })
            this.props.reloadApi(true);
            this.props.onHide();
            notifySuccess(res.message);
            if (this._unmounted) {
                this.setState({
                    qLoader: false,
                    userData: null
                });
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    qLoader: false
                });
            }
            notifyError(res.message);
        }
    }


    render() {

        // console.log(this.state.form);
        let form = {
            firstName:'',
            lastName:'',
            email:'',
            status:true
        }
        const { userData } = this.props;
        if(userData){
            form = {
                firstName:userData.firstName,
                lastName:userData.lastName,
                email:userData.email,
                status:parseInt(userData.status)?true:false
            }
        }

        const userSchema = Yup.object().shape({
            firstName: Yup.string().required('Please enter first name.'),
            lastName: Yup.string().required('Please enter last name'),
            email: Yup.string().email('Please enter valid email.').required('Please enter email.'),
            // password: Yup.string().required('Please enter password'),
            status: Yup.string()
        });
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header>
                    <Modal.Title>Update Super Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <Formik initialValues={form} validationSchema={userSchema} onSubmit={(values, actions) => this.formSubmit(values, actions)}>
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                            <form action="/" method="POST" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">First Name</label>
                                            <input type="text" name="firstName" className={errors.firstName && touched.firstName ? "form-control error" : "form-control"} placeholder="Enter first name" onChange={handleChange} onBlur={handleBlur} value={values.firstName} />
                                            {errors.firstName && touched.firstName ? <p className="text-danger">{errors.firstName}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Last Name</label>
                                            <input type="text" name="lastName" className={errors.lastName && touched.lastName ? "form-control error" : "form-control"} placeholder="Enter last name" onChange={handleChange} onBlur={handleBlur} value={values.lastName} />
                                            {errors.lastName && touched.lastName ? <p className="text-danger">{errors.lastName}</p> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Email</label>
                                            <input type="text" name="email" className={errors.email && touched.email ? "form-control error" : "form-control"} placeholder="Enter enter email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                                            {errors.email && touched.email ? <p className="text-danger">{errors.email}</p> : null}
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Password</label>
                                            <input type="password" name="password" className={errors.password && touched.password ? "form-control error" : "form-control"} placeholder="Enter enter password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
                                            {errors.password && touched.password ? <p className="text-danger">{errors.password}</p> : null}
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row">

                                    <div className="col-sm-6">
                                        <div className="form-group mb-4">
                                            {/* <label className="form-label">Status</label>
                                            <input type="checkbox" name="status" className="form-check-input" onChange={handleChange} onBlur={handleBlur} value={values.status} /> */}
                                            <div className="form-check">
                                                <input className="form-check-input" name="status" type="checkbox" id="flexCheckChecked" checked={values.status} onChange={handleChange} onBlur={handleBlur} value={values.status} />
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                    IsActive?
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group d-flex justify-content-end border-top pt-4">
                                    <button type="button" className="btn btn-secondary border-0 me-2" onClick={this.props.onHide}>Close</button>
                                    <button type="submit" aria-label="Log in" className="btn btn-success" disabled={this.state.qLoader}>{this.state.qLoader ? <SmallLoaderComponent color="white" /> : 'Update'}</button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        )
    }
}


export default withRouter(connect(null)(UpdateSuperAdminModal));
