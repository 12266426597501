import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DataTable from '../../components/DataTable';
import SmallLoaderComponent from '../../helper/SmallLoaderComponent';
import AddMemberModal from './AddMemberModal';
import EditMemberModal from './EditMemberModal';
import { customerMemberList, deleteMember, disableMember, enableMember, getCustomers } from '../../controller/customers.controller';
import { FiEye, FiChevronDown, FiChevronUp, FiPlus, FiMinus, FiUserPlus, FiEdit2, FiTrash, FiUserX, FiUserCheck } from 'react-icons/fi';
import SimpleTable from '../../components/SimpleTable';
import ConfirmModal from '../../components/confirmModal';
import { notifyError, notifySuccess } from '../../helper/Alert';

class Customers extends Component {

    _unmounted = false;
    expandRowIndex = null;
    memberList = [];

    constructor(props) {
        super(props);
        this.state = {
            customersList: [],
            memberList: [],
            membersLoader: false,
            mainUserData: null,
            singleMemberData: null,
            addMemberModal: false,
            editMemberModal: false,
            deleteMemberModal: false,
            enableMemberModal: false,
            disabledMemberModal: false,
            expandRows: [],
            mainTablePage: 0,
            subTablePage: 0,
            isUpdate: false
        }

        this.columns = [
            {
                dataField: 'memberCount',
                text: 'No of Users',
                sort: false,
                headerFormatter: this.headerCenterFormatter,
                formatter: this.noOfUserFormatter
            },
            {
                dataField: 'first_name',
                text: 'First Name',
                sort: true
            },
            {
                dataField: 'last_name',
                text: 'Last Name',
                sort: true
            },
            {
                dataField: 'email',
                text: 'Email',
                sort: true
            },
            {
                dataField: 'org_name',
                text: 'Organization Name',
                sort: true,
                formatter: this.org_name
            },
            {
                dataField: 'action',
                text: 'Action',
                sort: false,
                formatter: this.actionFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        this.subColumns = [
            {
                dataField: 'first_name',
                text: 'First Name',
                sort: true
            },
            {
                dataField: 'last_name',
                text: 'Last Name',
                sort: true
            },
            {
                dataField: 'email',
                text: 'Email',
                sort: true
            },
            {
                dataField: 'disabled',
                text: 'Action',
                sort: false,
                // classes:this.actionSubFormatter,
                formatter: this.actionSubFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        this.expandRow = {
            renderer: row => {
                console.log(row)
                return this.subTable(row)
            },
            expandByColumnOnly: true,
            onlyOneExpanding: true,
            showExpandColumn: true,
            expandColumnPosition: 'left',
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <span className="expand-table-button active"><FiMinus /></span>;
                }
                return <span className="expand-table-button"><FiPlus /></span>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="expand-table-button active"><FiChevronUp /></span>
                    );
                }
                return (
                    <span className="expand-table-button"><FiChevronDown /></span>
                );
            },

        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);
        this.getCustomerList();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { isUpdate } = this.state;
        if (prevState.isUpdate !== isUpdate) {
            // console.log(isUpdate);
            if (isUpdate) {
                this.setState({
                    isUpdate: false
                })
            }
        }


        return true;
    }

    getCustomerList = async () => {
        const res = await getCustomers();
        // console.log(res);
        if (res.status) {
            const arr = res.data;
            if (Array.isArray(arr) && arr.length > 0) {
                const newArr = arr.map(customer => {
                    customer.memberCount = customer.customersList.length
                    return customer
                })
                if (this._unmounted) {
                    this.setState({
                        customersList: newArr
                    })
                }
            } else {
                if (this._unmounted) {
                    this.setState({
                        customersList: []
                    })
                }
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    customersList: []
                })
            }
        }
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }

    org_name = (cell, row) => {
        return cell ? cell : '--'
    }

    actionFormatter = (cell, row) => {
        // console.log(row);
        return <div className="d-flex justify-content-center">
            {row.org_id ?
                <>
                    <button type="button" className="border-0 bg-transparent text-primary" style={{ cursor: 'pointer' }} onClick={() => this.openAddMemberModal(row)} ><FiUserPlus /></button>
                    <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.rediractPage(row)} ><FiEye /></button>
                </>
                :
                null
            }
        </div>
    }

    actionSubFormatter = (cell, row) => {
        // console.log(cell);
        return <div className="d-flex justify-content-center">
            {cell ?
                <button type="button" className="border-0 bg-transparent text-muted" style={{ cursor: 'pointer' }} onClick={() => this.openEnableModal(row)} ><FiUserX /></button>
                :
                <button type="button" className="border-0 bg-transparent text-primary" style={{ cursor: 'pointer' }} onClick={() => this.openDisabledModal(row)} ><FiUserCheck /></button>
            }
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openEditMemberModal(row)} ><FiEdit2 /></button>
            <button type="button" className="border-0 bg-transparent text-danger" style={{ cursor: 'pointer' }} onClick={() => this.openDeleteModal(row)} ><FiTrash /></button>

        </div>
    }
    noOfUserFormatter = (cell, row) => {
        // console.log(row);
        return <div className="d-flex justify-content-center">
            {cell > 0 ?
                <span className="badge bg-success rounded-pill ps-2 pe-2">{cell}</span>
                :
                <span className="badge bg-secondary rounded-pill ps-2 pe-2">0</span>
            }
        </div>
    }
    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    openEnableModal = (row) => {
        this.setState({
            enableMemberModal: true,
            singleMemberData: row
        })
    }

    closeEnableModal = () => {
        this.setState({
            enableMemberModal: false,
            singleMemberData: null
        })
    }

    submitEnableMember = async () => {
        const { singleMemberData: { user_id } } = this.state;

        const res = await enableMember(user_id);

        if (res.status) {
            const data = res.data;
            const { customersList } = this.state;
            if (Array.isArray(customersList) && customersList.length > 0) {
                const updateCustomerList = customersList.map(customer => {
                    if (Array.isArray(customer.customersList) && customer.customersList.length > 0) {
                        // customer.customersList = customer.customersList.filter(info => info.user_id !== user_id);
                        // customer.customersList = [...customer.customersList, this.state.singleMemberData];
                        // const index = customer.customersList.findIndex(info => info.user_id === user_id);
                        // customer.customersList[index].disabled = false;

                        customer.customersList = customer.customersList.filter(info => info.user_id !== data.user_id);
                        customer.customersList = [...customer.customersList, data];

                    }
                    return customer
                });

                this.setState({
                    customersList: updateCustomerList,
                    enableMemberModal: false,
                    singleMemberData: null
                });
            }
            notifySuccess(res.message)
        } else {
            notifyError(res.message)
        }
    }

    openDisabledModal = (row) => {
        this.setState({
            disabledMemberModal: true,
            singleMemberData: row
        })
    }

    closeDisabledModal = () => {
        this.setState({
            disabledMemberModal: false,
            singleMemberData: null
        })
    }

    submitDisableMember = async () => {
        const { singleMemberData: { user_id } } = this.state;

        const res = await disableMember(user_id);

        if (res.status) {
            const data = res.data;
            const { customersList } = this.state;
            if (Array.isArray(customersList) && customersList.length > 0) {
                const updateCustomerList = customersList.map(customer => {
                    if (Array.isArray(customer.customersList) && customer.customersList.length > 0) {
                        // customer.customersList = customer.customersList.filter(info => info.user_id !== user_id);
                        // customer.customersList = [...customer.customersList, this.state.singleMemberData];
                        // const index = customer.customersList.findIndex(info => info.user_id === user_id);
                        // customer.customersList[index].disabled = true;
                        // const index = customer.customersList.findIndex(info=>info.user_id === user_id);
                        customer.customersList = customer.customersList.filter(info => info.user_id !== data.user_id);
                        customer.customersList = [...customer.customersList, data];

                    }
                    return customer
                });

                this.setState({
                    customersList: updateCustomerList,
                    disabledMemberModal: false,
                    singleMemberData: null
                });

                notifySuccess(res.message);
            }

        } else {
            notifyError(res.message)
        }
    }

    openAddMemberModal = (row) => {
        // console.log(row);
        this.setState({
            addMemberModal: true,
            mainUserData: row
        })
    }

    closeAddMemberModal = () => {
        // console.log(row);
        this.setState({
            addMemberModal: false,
            mainUserData: null
        })
    }

    newMemberAdd = (data) => {
        const { customersList, mainUserData } = this.state;
        this.setState(() => ({
            customersList: [],
        }))
        const newArray = customersList.map(customer => {
            if (customer.user_id === mainUserData.user_id) {
                customer.customersList = [...customer.customersList, data]
                customer.memberCount = customer.memberCount + 1;
                // customer.customersList.length + 1
            }
            return customer
        })
        // console.log(data);
        console.log(newArray);
        this.setState(() => ({
            customersList: [],
            customersList: newArray,
            mainUserData: null,
            isUpdate: true
        }))
    }

    openEditMemberModal = (row) => {
        // console.log(row);
        this.setState({
            editMemberModal: true,
            singleMemberData: row
        })
    }

    closeEditMemberModal = () => {
        // console.log(row);
        this.setState({
            editMemberModal: false,
            singleMemberData: null
        })
    }

    existMemberEdit = (data) => {
        const { customersList } = this.state;
        if (Array.isArray(customersList) && customersList.length > 0) {
            const updateCustomerList = customersList.map(customer => {
                if (Array.isArray(customer.customersList) && customer.customersList.length > 0) {
                    customer.customersList = customer.customersList.filter(info => info.user_id !== data.user_id);
                    customer.customersList = [...customer.customersList, data];
                    // customer.customersList = customer.customersList.map(info =>{
                    //     if(info.user_id === data.user_id){
                    //         info.first_name = data.first_name
                    //         info.last_name = data.last_name
                    //         info.email = data.email
                    //         info.phone_number = data.phone_number
                    //         info.address = data.address
                    //     }
                    //     return info;
                    // })
                }
                return customer
            });

            this.setState({
                customersList: updateCustomerList
            })
        }
    }

    openDeleteModal = (row) => {
        // console.log(row);
        this.setState({
            deleteMemberModal: true,
            singleMemberData: row
        })
    }

    closeDeleteModal = () => {
        this.setState({
            deleteMemberModal: false,
            singleMemberData: null
        })
    }

    deleteMemberSubmit = async () => {
        const { singleMemberData: { user_id, role }, customersList } = this.state;

        const data = { user_id, role }
        // console.log(data);
        const res = await deleteMember(data);
        if (res.status) {
            this.setState(() => ({
                customersList: [],
            }))
            const updateCustomerList = customersList.map(customer => {
                if (Array.isArray(customer.customersList) && customer.customersList.length > 0) {
                    customer.customersList = customer.customersList.filter(info => info.user_id !== user_id)
                    customer.memberCount = customer.memberCount - 1;
                }
                return customer
            });
            console.log(updateCustomerList);
            this.setState(() => ({
                customersList: updateCustomerList,
                deleteMemberModal: false,
                singleMemberData: null,
                isUpdate: true
            }))
            notifySuccess(res.message)
        } else {
            notifyError(res.message)
        }

    }

    rediractPage = (row) => {
        // console.log(row)
        this.props.history.push(`/admin/customer/reports?user_id=${row.user_id}&org_id=${row.org_id}`)
    }

    expandRowFunction = (row, isExpand, rowIndex, e) => {
        // console.log(row);
        // console.log(isExpand);
        // console.log(rowIndex);
        // console.log(e);
        if (isExpand) {
            this.expandRowIndex = rowIndex;
            this.setState({
                expandRowIndex: rowIndex,
                mainUserData: row
            })
        } else {
            this.expandRowIndex = null
            this.setState({
                expandRowIndex: null
            })
        }
    }

    subTable = async (row) => {
        this.expandRowIndex = row;
        // console.log(row);
        const { memberList } = this.state;
        // console.log(memberList);
        // // this.memberList = membersList;
        Array.isArray(memberList) && memberList.length > 0 ?
            <SimpleTable keyField={"user_id"} noDataIndication={this.emptyRender} columns={this.subColumns} data={memberList} classes="table table-hover my-0" wrapperClasses="table-responsive bg-table-light p-3" />
            :
            <SimpleTable keyField={"user_id"} noDataIndication={this.emptyRender} columns={this.subColumns} data={[]} classes="table table-hover my-0" wrapperClasses="table-responsive bg-table-light p-3" />



    }

    onClickExpand = (row, isExpand, rowIndex, e) => {
        console.log(row);
        if (isExpand) {
            this.expandRowIndex = row;
            this.setState(() => ({
                isUpdate: true,
                expandRows: [rowIndex]
            }))
        } else {
            this.setState(() => ({
                isUpdate: false,
                expandRows: []
            }))
        }
    }

    render() {

        // console.log(this.state.customersList);



        // console.log(this.expandRowIndex);
        // const { memberList } = this.state;
        // console.log(memberList);
        // console.log(this.state.expandRows);
        const expandRow = {
            renderer: row => {
                this.expandRowIndex = row.customersList.sort((a, b) => a.user_id - b.user_id);
                // console.log(this.expandRowIndex);
                return <SimpleTable keyField={"user_id"} noDataIndication={this.emptyRender} columns={this.subColumns} data={this.expandRowIndex} classes="table table-hover my-0" wrapperClasses="table-responsive bg-table-light p-3" />
            },
            // expanded: this.state.expandRows,
            expandByColumnOnly: true,
            onlyOneExpanding: true,
            showExpandColumn: true,
            expandColumnPosition: 'left',
            // onExpand: (row, isExpand, rowIndex, e) => this.onClickExpand(row, isExpand, rowIndex, e),
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <span className="expand-table-button active"><FiMinus /></span>;
                }
                return <span className="expand-table-button"><FiPlus /></span>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="expand-table-button active"><FiChevronUp /></span>
                    );
                }
                return (
                    <span className="expand-table-button"><FiChevronDown /></span>
                );
            },

        }



        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3 d-flex align-items-start justify-content-between">
                    <strong>Customers List</strong>
                    {/* <button type="button" className="btn btn-dark" onClick={this.addAdminModal}>Add Super Admin</button> */}
                </h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <DataTable
                                keyField="user_id"
                                data={this.state.customersList}
                                columns={this.columns}
                                expandRow={expandRow}
                                noDataIndication={this.emptyRender}
                            />
                        </div>
                    </div>
                </div>
                {this.state.addMemberModal ? <AddMemberModal show={this.state.addMemberModal} onSubmit={this.newMemberAdd} onHide={this.closeAddMemberModal} userData={this.state.mainUserData} /> : null}
                {this.state.editMemberModal ? <EditMemberModal show={this.state.editMemberModal} onSubmit={this.existMemberEdit} userData={this.state.singleMemberData} onHide={this.closeEditMemberModal} /> : null}
                {this.state.deleteMemberModal ?
                    <ConfirmModal
                        text={'Are you sure you want to delete this user?'}
                        show={this.state.deleteMemberModal}
                        onSubmit={this.deleteMemberSubmit}
                        onHide={this.closeDeleteModal}
                    />
                    :
                    null
                }

                {this.state.enableMemberModal ?
                    <ConfirmModal
                        text={'Are you sure you want to enable this user?'}
                        show={this.state.enableMemberModal}
                        onSubmit={this.submitEnableMember}
                        onHide={this.closeEnableModal}
                    />
                    :
                    null
                }

                {this.state.disabledMemberModal ?
                    <ConfirmModal
                        text={'Are you sure you want to disable this user?'}
                        show={this.state.disabledMemberModal}
                        onSubmit={this.submitDisableMember}
                        onHide={this.closeDisabledModal}
                    />
                    :
                    null
                }
            </div>
        )
    }
}

export default withRouter(connect(null)(Customers))
