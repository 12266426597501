import React, { Component } from 'react';
import SimpleBar from 'simplebar';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MetisMenu from 'metismenujs';
// import { FiSliders, FiUser, FiAlignLeft, FiBook, FiLayers } from "react-icons/fi";
import { FiSliders, FiUser, FiUsers } from "react-icons/fi";
import Logo from '../assets/img/Logo.svg'
import 'metismenujs/dist/metismenujs.css'

class TheSidebar extends Component {

    componentDidMount() {
        this.scrollFunction();
        document.addEventListener('scroll', this.scrollFunction)

        new MetisMenu("#menu", {});

        var matchingMenuItem = null;
        var ul = document.getElementById("menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }

    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollFunction)
    }

    activateParentDropdown = item => {
        item.classList.add("mm-active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active"); // li
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");
                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    scrollFunction = () => {
        const sidebarID = document.getElementById('sidebarScroll');
        new SimpleBar(sidebarID, { autoHide: true });
        // const simpleBar = new SimpleBar(document.querySelector('data-simplebar'));
        // simpleBar.getScrollElement();
        // SimpleBar(document.getElementById('myElement'));
    }

    render() {

        const { isSidebar } = this.props;

        return (
            <nav id="sidebar" className={isSidebar ? "sidebar js-sidebar collapsed" : "sidebar js-sidebar"}>
                <div id="sidebarScroll" className="sidebar-content js-simplebar" data-simplebar>
                    <Link className="sidebar-brand" to="/admin/dashboard">
                        <span className="feather align-middle">
                            {/* AdminKit */}
                            <img src={Logo} style={{ maxHeight: '28px' }} alt="logo" />
                        </span>
                    </Link>

                    <ul className="sidebar-nav metismenu" id="menu">
                        <li className="sidebar-header"></li>

                        <li className="sidebar-item">
                            <NavLink className="sidebar-link" activeClassName="active" to="/admin/dashboard">
                                <FiSliders />
                                <span className="align-middle">Dashboard</span>
                            </NavLink>
                        </li>

                        <li className="sidebar-item">
                            <NavLink className="sidebar-link" activeClassName="active" to="/admin/users">
                                <FiUser />
                                <span className="align-middle">Super Admins</span>
                            </NavLink>
                        </li>

                        <li className="sidebar-item">
                            <NavLink className="sidebar-link" activeClassName="active" to="/admin/customers">
                                <FiUsers />
                                <span className="align-middle">Customers</span>
                            </NavLink>
                        </li>

                        {/* <li className="sidebar-item">
                            <Link className="sidebar-link" aria-expanded={false} to="/#">
                                <FiAlignLeft />
                                <span className="align-middle">Questions <span className="float-right "><FiChevronDown /></span></span>
                                
                            </Link>
                            <ul className="submenu">
                                <li className="sidebar-item"><Link to="/admin-dashboard" className="sidebar-link">Dashboard 1</Link></li>
                                <li className="sidebar-item"><Link to="/admin-dashboard-2" className="sidebar-link">Dashboard 2</Link></li>
                                <li className="sidebar-item"><Link to="/admin-dashboard-3" className="sidebar-link">Dashboard 3</Link></li>
                                <li className="sidebar-item"><Link to="/admin-dashboard-4" className="sidebar-link">Dashboard 4</Link></li>
                            </ul>
                        </li> */}

                        {/* <li className="sidebar-item">
                            <a className="sidebar-link" href="pages-sign-in.html">
                                <i className="align-middle" data-feather="log-in"></i> <span className="align-middle">Sign In</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="pages-sign-up.html">
                                <i className="align-middle" data-feather="user-plus"></i> <span className="align-middle">Sign
                                    Up</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="pages-blank.html">
                                <i className="align-middle" data-feather="book"></i> <span className="align-middle">Blank</span>
                            </a>
                        </li>

                        <li className="sidebar-header">
                            {'Tools & Components'}
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="ui-buttons.html">
                                <i className="align-middle" data-feather="square"></i> <span className="align-middle">Buttons</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="ui-forms.html">
                                <i className="align-middle" data-feather="check-square"></i> <span
                                    className="align-middle">Forms</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="ui-cards.html">
                                <i className="align-middle" data-feather="grid"></i> <span className="align-middle">Cards</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="ui-typography.html">
                                <i className="align-middle" data-feather="align-left"></i> <span
                                    className="align-middle">Typography</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="icons-feather.html">
                                <i className="align-middle" data-feather="coffee"></i> <span className="align-middle">Icons</span>
                            </a>
                        </li>

                        <li className="sidebar-header">
                            Plugins & Addons
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="charts-chartjs.html">
                                <i className="align-middle" data-feather="bar-chart-2"></i> <span
                                    className="align-middle">Charts</span>
                            </a>
                        </li>

                        <li className="sidebar-item">
                            <a className="sidebar-link" href="maps-google.html">
                                <i className="align-middle" data-feather="map"></i> <span className="align-middle">Maps</span>
                            </a>
                        </li> */}
                    </ul>
                </div>
            </nav>
        )
    }
}

const mapStateToProps = state => ({
    isSidebar: state.layout.openSideBar
})

export default withRouter(connect(mapStateToProps)(TheSidebar))