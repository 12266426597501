import React from 'react';
// import { useSelector } from 'react-redux';
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
} from 'recharts';

export default function UsageBarChart(props) {
// const analysis = useSelector((s) => s.analysis.fileAnalysis);
const analysis = props.fileAnalysis;
  return (
    <div
      style={{
        background: 'white',
        marginTop: '1rem',
        textAlign: 'center',
        position: 'relative',
        width: '100%',
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '3rem',
      }}
    >
      <span
        style={{
          fontWeight: 'bold',
          fontSize: '1.25rem',
          position: 'absolute',
          zIndex: 99,
          left: 0,
          right: 0,
          top: '2%',
        }}
      >
        Usage
      </span>
      <ResponsiveContainer className="usage-bar-chart">
        <BarChart
          layout="vertical"
          width={600}
          height={300}
          data={[
              { name: '>5000MB', 'Users count': analysis?.data?.usage?.file_above_5000M },
              { name: '1000-4999MB', 'Users count': analysis?.data?.usage?.file_1000M_to_5000M },
              { name: '100-999MB', 'Users count': analysis?.data?.usage?.file_100M_to_1000M },
              { name: '1-99MB', 'Users count': analysis?.data?.usage?.file_below_100M },
          ]}
          margin={{ top: 20, right: 30, left: 50, bottom: 10 }}
        >
          <XAxis type="number"/>
          <YAxis dataKey="name" type="category"/>
          <Tooltip cursor={{ fill: 'transparent' }}/>
          <Bar dataKey="Users count" barSize={30} fill="#413ea0"/>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
