/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import {
  Tooltip,
  LineChart,
  XAxis,
  YAxis,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';

const CustomizedAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-50)"
    >
      {payload.value}
    </text>
  </g>
);

export default function AnalysisByDateAll(props) {
  const [analysis, setAnalysis] = useState({ isLoading: true, data: [] });

  useEffect(() => {
    // console.log(props);
    // if (!props.fileAnalysis.isLoading && props.fileAnalysis.data.length > 0) {
    setAnalysis({ isLoading: props.fileAnalysis.isLoading, data: props.fileAnalysis.data.reverse() });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fileAnalysis.isLoading, props.fileAnalysis.data]);
  const colors = [
    '#393F93',
    '#95C675',
    '#FEE801',
    '#FFBD28',
    '#FF8A66',
    '#00A9FF',
    '#FF5A46',
  ];
  const [mySVG] = document.getElementsByClassName('recharts-surface');
  if (mySVG) mySVG.setAttribute('viewBox', '90 90 220 220');
  return (
    <div
      style={{
        background: 'white',
        marginTop: '1rem',
        textAlign: 'center',
        position: 'relative',
        width: '100%',
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '3rem',
      }}
    >
      <span
        style={{
          fontWeight: 'bold',
          fontSize: '1.25rem',
          position: 'absolute',
          zIndex: 99,
          left: 0,
          right: 0,
          top: '2%',
        }}
      >
        Total documents created by type
      </span>
      {!analysis.isLoading && analysis.data.length ? (
        <ResponsiveContainer>
          <LineChart
            dataKey="value"
            width={600}
            height={300}
            data={analysis.data}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
            wrapperStyle={{ marginTop: '10rem', background: 'red' }}
          >
            <XAxis
              dataKey="day"
              height={60}
              tick={<CustomizedAxisTick />}
              interval={0}
            />
            <YAxis />
            <Tooltip />
            <Legend
              wrapperStyle={{
                paddingTop: '30px',
              }}
            />
            <Line type="monotone" dataKey="document" stroke={colors[0]} />
            <Line type="monotone" dataKey="txt" stroke={colors[1]} />
            <Line type="monotone" dataKey="image" stroke={colors[2]} />
            <Line type="monotone" dataKey="powerpoint" stroke={colors[3]} />
            <Line type="monotone" dataKey="excel" stroke={colors[4]} />
            <Line type="monotone" dataKey="pdf" stroke={colors[5]} />
          </LineChart>
        </ResponsiveContainer>
      ) : null}
    </div>
  );
}

