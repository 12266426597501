import React, { useState, useEffect } from 'react';
import {
    Tooltip,
    LineChart,
    XAxis,
    YAxis,
    Legend,
    Line,
    ResponsiveContainer,
} from 'recharts';

const CustomizedAxisTick = ({ x, y, payload }) => (
    <g transform={`translate(${x},${y})`}>
        <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="#666"
            transform="rotate(-50)"
        >
            {payload.value}
        </text>
    </g>
);


const zoomFoun = (e) =>{
    console.log(e);
    return 2
}

export default function ChartLine(props) {
    const [colors, setColors] = useState([])
    const [data, setData] = useState([])
    const [labels, setLabels] = useState([])
    const [xdataKey, setXdataKey] = useState("")

    useEffect(() => {
        setColors(props.colors)
        setData(props.data)
        setLabels(props.labels)
        setXdataKey(props.xdataKey)
    }, [props, props.data, props.colors, props.labels, props.xdataKey])

    // console.log(data);
    return data.length > 0 ?
        <ResponsiveContainer width="100%" height="100%" debounce={zoomFoun}>
            <LineChart
                width={600}
                height={200}
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 80 }}
                wrapperStyle={{ marginTop: '10rem', background: 'red' }}
            >
                <XAxis
                    dataKey={xdataKey}
                    height={60}
                    tick={<CustomizedAxisTick />}
                    interval={0}
                />
                <YAxis />
                <Tooltip />
                <Legend
                    wrapperStyle={{
                        paddingTop: '30px',
                    }}
                />
                {Array.isArray(labels) && labels.length > 0 ?
                    labels.map((info, i) => <Line key={i} type="monotone" dataKey={info.dataKey} name={info.label} stroke={colors[i]} />)
                    :
                    null
                }
            </LineChart>
        </ResponsiveContainer>
        :
        null
}