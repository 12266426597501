/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getFileAnalysisByDate } from '../../../redux/actions/analysis';
import {
  Tooltip,
  LineChart,
  XAxis,
  YAxis,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts';
// import PropTypes from 'prop-types';

const CustomizedAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-50)"
    >
      {payload.value}
    </text>
  </g>
  );

export default function AnalysisByDate(props) {
  const bydate = props.fileAnalysis.bydate;
  // const all = props.all;
  // const time = props.time;
  // const unit_of_time = props.unit_of_time;
  // const fileAnalysis = props.fileAnalysis
  // const dispatch = useDispatch();
  // const analysis = useSelector((s) => s.analysis[`by_${bydate}`]);
  const analysis = props.fileAnalysis;
  // console.log(analysis);
  // useEffect(() => {
  //   // dispatch(getFileAnalysisByDate({ bydate, all, time, unit_of_time }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [time, unit_of_time]);
  const colors = [
    '#393F93',
    '#95C675',
    '#FEE801',
    '#FFBD28',
    '#FF8A66',
    '#00A9FF',
    '#FF5A46',
  ];
  return (
    <div
      style={{
        background: 'white',
        marginTop: '1rem',
        textAlign: 'center',
        position: 'relative',
        width: '100%',
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '3rem',
      }}
    >
      <span
        style={{
          fontWeight: 'bold',
          fontSize: '1.25rem',
          position: 'absolute',
          zIndex: 99,
          left: 0,
          right: 0,
          top: '2%',
        }}
      >
        Documents {bydate === 'created_at' ? 'created' : 'updated'} by type
      </span>
      {!analysis.isLoading && analysis.data.length ? (
        <ResponsiveContainer>
          <LineChart
            width={600}
            height={300}
            data={analysis.data}
            margin={{ top: 20, right: 30, left: 20, bottom: 80 }}
            wrapperStyle={{ marginTop: '10rem', background: 'red' }}
          >
            <XAxis
              dataKey="day"
              height={60}
              tick={<CustomizedAxisTick/>}
              interval={0}
            />
            <YAxis/>
            <Tooltip/>
            <Legend
              wrapperStyle={{
              paddingTop: '30px',
            }}
            />
            <Line type="monotone" dataKey="document" stroke={colors[0]}/>
            <Line type="monotone" dataKey="txt" stroke={colors[1]}/>
            <Line type="monotone" dataKey="image" stroke={colors[2]}/>
            <Line type="monotone" dataKey="powerpoint" stroke={colors[3]}/>
            <Line type="monotone" dataKey="excel" stroke={colors[4]}/>
            <Line type="monotone" dataKey="pdf" stroke={colors[5]}/>
          </LineChart>
        </ResponsiveContainer>
      ) :
        null
      }
    </div>
  );
}
// AnalysisByDate.propTypes = {
//     bydate: PropTypes.oneOf(['created_at', 'update_at']).isRequired,
//     all: PropTypes.bool,
//     time: PropTypes.number,
//     unit_of_time: PropTypes.string,
// };
// AnalysisByDate.defaultProps = {
//   all: undefined,
//   time: undefined,
//   unit_of_time: undefined,
// };
