import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ChartLine from '../../components/Chart/ChartLine';
// import LineChatWithZoom from '../../components/Chart/LineChatWithZoom';
import { getPlanAnalysis } from '../../controller/dashboard.controller';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { FiChevronDown } from 'react-icons/fi';
// import { FiTruck, FiUsers, FiDollarSign, FiShoppingCart } from 'react-icons/fi';
// import Layout from '../../components/Layout';

class Dashboard extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            analysis: [],
            chartDate: 0,
            startDate: new Date(),
            endDate: new Date(),
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);

        const currentDate = new Date();
        const last7Days = moment(currentDate).subtract(7, "d")

        if (this._unmounted) {
            this.setState({
                startDate: last7Days,
                endDate: new Date()
            })
        }

        this.getAnalysis(last7Days, currentDate);
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    getAnalysis = async (startDate, endDate) => {
        if (this._unmounted) {
            this.setState({
                loading: true
            })
        }
        const res = await getPlanAnalysis({ startDate, endDate });
        if (res.status) {

            let analysisData = [];
            const data = res.data;
            if (data.length > 0) {
                analysisData = data.map(info => {
                    let PROMonth = '';
                    let PROYear = '';
                    let STANDARDMonth = '';
                    let STANDARDYear = '';
                    let BASICMonth = '';
                    let BASICYear = '';
                    if (Array.isArray(info.planData) && info.planData.length > 0) {
                        PROMonth = info.planData.find(info => info.planName === "PRO" && info.planType === "month")?.userCount
                        PROYear = info.planData.find(info => info.planName === "PRO" && info.planType === "year").userCount
                        STANDARDMonth = info.planData.find(info => info.planName === "STANDARD" && info.planType === "month").userCount
                        STANDARDYear = info.planData.find(info => info.planName === "STANDARD" && info.planType === "year").userCount
                        BASICMonth = info.planData.find(info => info.planName === "BASIC" && info.planType === "month").userCount
                        BASICYear = info.planData.find(info => info.planName === "BASIC" && info.planType === "year").userCount
                    }
                    return {
                        date: info.date,
                        line1Key: 'BASIC - Month',
                        line2Key: 'BASIC - Year',
                        line3Key: 'STANDARD - Month',
                        line4Key: 'STANDARD - Year',
                        line5Key: 'PRO - Month',
                        line6Key: 'PRO - Year',
                        PROMonth,
                        PROYear,
                        STANDARDMonth,
                        STANDARDYear,
                        BASICMonth,
                        BASICYear
                    }
                })
                // console.log(analysisData);

                // console.log(map);
            }

            if (this._unmounted) {
                this.setState({
                    analysis: analysisData,
                    loading: false
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    analysis: [],
                    loading: false
                })
            }
        }
    }

    filterDateAnalysis = (e, picker) => {
        // console.log(picker);
        const { startDate, endDate } = picker;
        const sDate = startDate._d;
        const eDate = endDate._d;
        if (this._unmounted) {
            this.setState({
                startDate: sDate,
                endDate: eDate
            })
        }

        this.getAnalysis(sDate, eDate);
    }

    render() {

        const colors = [
            '#393F93',
            '#95C675',
            '#FEE801',
            '#FFBD28',
            '#FF8A66',
            '#00A9FF',
            '#FF5A46',
        ];

        const labels = [
            { dataKey: "BASICMonth", label: "BASIC - Month" },
            { dataKey: "BASICYear", label: "BASIC - Year" },
            { dataKey: "STANDARDMonth", label: "STANDARD - Month" },
            { dataKey: "STANDARDYear", label: "STANDARD - Year" },
            { dataKey: "PROMonth", label: "PRO - Month" },
            { dataKey: "PROYear", label: "PRO - Year" }
        ]
        // const labels2 = ["BASIC_Month", "BASIC_Year", "STANDARD_Month","STANDARD_Year","PRO_Month","PRO_Year"]

        const { loading, startDate, endDate } = this.state;

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3"><strong>Dashboard</strong> </h1>
                <div className="row">
                    {/* <div className="col-xl-6 col-xxl-5 d-flex">
                        <div className="w-100">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">Sales</h5>
                                                </div>

                                                <div className="col-auto">
                                                    <div className="stat text-primary">
                                                        <FiTruck />
                                                    </div>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">2.382</h1>
                                            <div className="mb-0">
                                                <span className="text-danger"> <i
                                                    className="mdi mdi-arrow-bottom-right"></i> -3.65% </span>
                                                <span className="text-muted">Since last week</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">Visitors</h5>
                                                </div>

                                                <div className="col-auto">
                                                    <div className="stat text-primary">
                                                        <FiUsers />
                                                    </div>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">14.212</h1>
                                            <div className="mb-0">
                                                <span className="text-success"> <i
                                                    className="mdi mdi-arrow-bottom-right"></i> 5.25% </span>
                                                <span className="text-muted">Since last week</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">Earnings</h5>
                                                </div>

                                                <div className="col-auto">
                                                    <div className="stat text-primary">
                                                        <FiDollarSign />
                                                    </div>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">$21.300</h1>
                                            <div className="mb-0">
                                                <span className="text-success"> <i
                                                    className="mdi mdi-arrow-bottom-right"></i> 6.65% </span>
                                                <span className="text-muted">Since last week</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title">Orders</h5>
                                                </div>

                                                <div className="col-auto">
                                                    <div className="stat text-primary">
                                                        <FiShoppingCart />
                                                    </div>
                                                </div>
                                            </div>
                                            <h1 className="mt-1 mb-3">64</h1>
                                            <div className="mb-0">
                                                <span className="text-danger"> <i
                                                    className="mdi mdi-arrow-bottom-right"></i> -2.25% </span>
                                                <span className="text-muted">Since last week</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-xl-12 col-xxl-12">
                        <div className="card flex-fill w-100">
                            <div className="card-header d-flex justify-content-between">

                                <h5 className="card-title mb-0">Recent Movement</h5>
                                <div className="date-range-wrap position-relative">
                                    <DateRangePicker
                                        initialSettings={{
                                            drops: "custom-range-picker",
                                            startDate: moment(startDate).format('DD MMM YYYY'),
                                            endDate: moment(endDate).format('DD MMM YYYY'),
                                            locale: { format: 'DD MMM YYYY' },
                                            maxDate: new Date(),
                                            applyClass: "bgr"
                                        }}
                                        onApply={this.filterDateAnalysis}
                                    >
                                        {/* <input className="form-control" /> */}
                                        <button type="button" className="date-range-btn">{`${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}`}<i className="icon"><FiChevronDown /></i></button>
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="card-body py-3">
                                <div className="chart chart-sm" style={{ height: 500 }}>
                                    {!loading ? <ChartLine colors={colors} data={this.state.analysis} labels={labels} xdataKey="date" /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(connect(null)(Dashboard))