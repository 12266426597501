import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getFileAnalysis, getFileAnalysisByDate } from '../../../controller/customers.controller';
import UsageStackedChart from './UsageStackedChart';
import Analysis from './Analysis';
import AnalysisByDate from './AnalysisByDate';
import AnalysisByDateAll from './AnalysisByDateAll';
import UsageBarChart from './UsageBarChart';

const unitOfTimes = ['days', 'weeks', 'months', 'months', 'months'];
const times = [7, 2, 1, 3, 6];

class Reports extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            org_id: 0,
            time1: 0,
            time2: 0,
            fileAnalysis: {
                isLoading: false,
                data:null
            },
            by_created_at: {
                isLoading: false,
                bydate: '',
                data: [],
            },
            by_updated_at: {
                isLoading: false,
                bydate: '',
                data: [],
            },
            created_at_allDate: {
                isLoading: false,
                data: []
            }
        }
    }

    componentDidMount() {
        this._unmounted = true;
        const { org_id } = this.props;
        const { time1, time2 } = this.state;
        // console.log(this.props);
        if (this._unmounted) {
            this.setState({
                org_id: org_id
            })
        }
        this.fileAnalysis(org_id);
        this.fileAnalysisByDate('created_at', undefined, times[time1], unitOfTimes[time1], org_id)
        this.fileAnalysisByDate('updated_at', undefined, times[time2], unitOfTimes[time2], org_id)
        this.fileAnalysisByAllDate('created_at', true, org_id)
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    fileAnalysis = async (id) => {
        if (this._unmounted) {
            this.setState({
                fileAnalysis:{
                    ...this.state.fileAnalysis,
                    isLoading:true
                }
            })
        }
        const res = await getFileAnalysis(id);
        // console.log(res);
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    fileAnalysis:{
                        ...this.state.fileAnalysis,
                        isLoading:false,
                        data:res.data
                    }
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    fileAnalysis:{
                        ...this.state.fileAnalysis,
                        isLoading:false,
                        data:null
                    }
                })
            }
        }
    }

    fileAnalysisByDate = async (bydate, all, time, unit_of_time, org_id) => {

        if (bydate === "created_at") {
            if (this._unmounted) {
                this.setState({
                    by_created_at: {
                        ...this.state.by_created_at,
                        isLoading: true,
                    }
                })
            }
        }

        if (bydate === "updated_at") {
            if (this._unmounted) {
                this.setState({
                    by_updated_at: {
                        ...this.state.by_updated_at,
                        isLoading: true,
                    }
                })
            }
        }

        const res = await getFileAnalysisByDate({ bydate, all, time, unit_of_time, org_id });
        // console.log(res);
        if (res.status) {
            if (res.data.bydate === "created_at") {

                if (this._unmounted) {
                    this.setState({
                        by_created_at: {
                            ...this.state.by_created_at,
                            bydate: res.data.bydate,
                            isLoading: false,
                            data: res.data.result,
                        },
                    })
                }
            } else {
                if (this._unmounted) {
                    this.setState({
                        by_updated_at: {
                            ...this.state.by_updated_at,
                            bydate: res.data.bydate,
                            isLoading: false,
                            data: res.data.result,
                        },
                    })
                }
            }
        } else {

            if (this._unmounted) {
                this.setState({
                    by_created_at: {
                        ...this.state.by_created_at,
                        bydate: 'created_at',
                        isLoading: false,
                        data: [],
                    },
                    by_updated_at: {
                        ...this.state.by_updated_at,
                        bydate: 'updated_at',
                        isLoading: false,
                        data: [],
                    },
                })
            }
        }
    }

    fileAnalysisByAllDate = async (bydate, all, org_id) => {

        if (bydate === "created_at") {
            if (this._unmounted) {
                this.setState({
                    created_at_allDate: {
                        ...this.state.created_at_allDate,
                        isLoading: true,
                    }
                })
            }
        }

        const res = await getFileAnalysisByDate({ bydate, all, org_id });
        // console.log(res);
        if (res.status) {

            if (this._unmounted) {
                this.setState({
                    created_at_allDate: {
                        ...this.state.created_at_allDate,
                        isLoading: false,
                        data: res.data.result,
                    },
                })
            }
        } else {
            if (this._unmounted) {
                this.setState({
                    created_at_allDate: {
                        ...this.state.created_at_allDate,
                        isLoading: false,
                        data: [],
                    },
                })
            }
        }
    }

    time1Handler = (time) => {
        if (this._unmounted) {
            this.setState({
                time1: time
            })
        }

        this.fileAnalysisByDate('created_at', undefined, times[time], unitOfTimes[time], this.state.org_id)
    }

    time2Handler = (time) => {
        if (this._unmounted) {
            this.setState({
                time2: time
            })
        }
        this.fileAnalysisByDate('updated_at', undefined, times[time], unitOfTimes[time], this.state.org_id)
    }

    render() {



        const { time1, time2 } = this.state;

        return (
            <div className="container-fluid p-0">
                {/* <h1 className="h3 mb-3 d-flex align-items-start justify-content-between">
                    <strong>Reports</strong>
                    {/* <button type="button" className="btn btn-dark" onClick={this.addAdminModal}>Add Super Admin</button> *
                </h1> */}
                <div className="row">
                    <div className="col-6 col-lg-6 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <UsageStackedChart fileAnalysis={this.state.fileAnalysis} />
                        </div>
                    </div>

                    <div className="col-6 col-lg-6 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <Analysis fileAnalysis={this.state.fileAnalysis} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-lg-6 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <AnalysisByDate fileAnalysis={this.state.by_created_at} />
                            <div className="analysis-buttons">
                                <button type="button" onClick={() => this.time1Handler(4)} className={`analysis-button ${time1 === 4 ? 'active' : undefined}`}>6 Months</button>
                                <button type="button" onClick={() => this.time1Handler(3)} className={`analysis-button ${time1 === 3 ? 'active' : undefined}`}>3 Months</button>
                                <button type="button" onClick={() => this.time1Handler(2)} className={`analysis-button ${time1 === 2 ? 'active' : undefined}`}>1 Months</button>
                                <button type="button" onClick={() => this.time1Handler(1)} className={`analysis-button ${time1 === 1 ? 'active' : undefined}`}>2 Weeks</button>
                                <button type="button" onClick={() => this.time1Handler(0)} className={`analysis-button ${time1 === 0 ? 'active' : undefined}`}>Last 7 days</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-lg-6 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <AnalysisByDate fileAnalysis={this.state.by_updated_at} />
                            <div className="analysis-buttons">
                                <button type="button" onClick={() => this.time2Handler(4)} className={`analysis-button ${time2 === 4 ? 'active' : undefined}`}>6 Months</button>
                                <button type="button" onClick={() => this.time2Handler(3)} className={`analysis-button ${time2 === 3 ? 'active' : undefined}`}>3 Months</button>
                                <button type="button" onClick={() => this.time2Handler(2)} className={`analysis-button ${time2 === 2 ? 'active' : undefined}`}>1 Months</button>
                                <button type="button" onClick={() => this.time2Handler(1)} className={`analysis-button ${time2 === 1 ? 'active' : undefined}`}>2 Weeks</button>
                                <button type="button" onClick={() => this.time2Handler(0)} className={`analysis-button ${time2 === 0 ? 'active' : undefined}`}>Last 7 days</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-lg-6 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                        <AnalysisByDateAll fileAnalysis={this.state.created_at_allDate} />
                        </div>
                    </div>

                    <div className="col-6 col-lg-6 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <UsageBarChart fileAnalysis={this.state.fileAnalysis} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null)(Reports))
