import axios from 'axios';


const url = `https://cms.docquik.io:4000`;
// const url = `http://15.206.63.105:4000`;

export const apiHelper = (endPoint, method, data, headers) => {
    return new Promise((resolve, reject) => {
        return axios({
            url: `${url}/${endPoint}`,
            method,
            data,
            headers
        })
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    })
}