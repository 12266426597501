import { apiHelper } from "../helper/api.helper";
import { jwtDecode } from "../helper/jwt.helper";
import axios from 'axios';

const url = `https://cms.docquik.io:4000`;

export const getCustomers = async () => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }
        const response = await apiHelper('admin/customers/admin', 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: "Something went wrong." };
    }
}

export const getFileAnalysis = async (id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }
        const response = await apiHelper(`admin/file-analysis?org_id=${id}`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: "Something went wrong." };
    }
}

export const customerMemberList = async (org_id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }
        const response = await apiHelper(`admin/customers/user/${org_id}`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: "Something went wrong." };
    }
}

export const memberList = async (org_id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }
        const response = await apiHelper(`admin/member-list?org_id=${org_id}`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: "Something went wrong." };
    }
}

export const getMemberDocuments = async ({ member_id, user_id, org_id }) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }
        const response = await apiHelper(`admin/members-documents?owner_id=${member_id}&user_id=${user_id}&org_id=${org_id}`, 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: "Something went wrong." };
    }
}

export const restoreDocuments = async ({ member_id, folder_ids, file_ids, user_id, org_id }) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        let formData = new FormData();
        formData.append('member_id', member_id)
        // if (folder_ids) {
        formData.append('folder_ids', JSON.stringify(folder_ids))
        // }
        formData.append('file_ids', JSON.stringify(file_ids))

        const response = await apiHelper(`admin/doc-restore?user_id=${user_id}&org_id=${org_id}`, 'PUT', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: [], message: res.message };
    } catch (err) {
        return { status: false, message: err.message };
    }
}

export const getFileAnalysisByDate = async ({ bydate, all = undefined, time, unit_of_time, org_id }) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }
        // const response = await apiHelper(`admin/file-analysis?bydate=${bydate}&all=${all}&time=${time}&unit_of_time=${unit_of_time}`, 'GET', null, header);
        const response = await axios({
            method: 'GET',
            url: `${url}/admin/file-analysis-by-date`,
            headers: header,
            params: { bydate, all, time, unit_of_time, org_id },
        });
        const res = response.data;
        // console.log(res);
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };

    } catch (err) {
        return { status: false, message: "Something went wrong." };
    }
}

export const addNewMember = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const { first_name, last_name, email, password, phone_number, address, user_id, storage, plan_id, org_id } = data;

        let formData = new FormData();
        formData.append('first_name', first_name)
        formData.append('last_name', last_name)
        formData.append('email', email)
        formData.append('password', password)
        formData.append('phone_number', phone_number)
        formData.append('address', address)
        formData.append('user_id', user_id)
        formData.append('storage', storage)
        formData.append('plan_id', plan_id)
        formData.append('org_id', org_id)

        const response = await apiHelper(`admin/add-member`, 'POST', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: err.message };
    }
}

export const updateExistMember = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const { first_name, last_name, email, phone_number, address, user_id } = data;

        let formData = new FormData();
        formData.append('first_name', first_name)
        formData.append('last_name', last_name)
        formData.append('email', email)
        formData.append('phone_number', phone_number)
        formData.append('address', address)
        formData.append('user_id', user_id)

        const response = await apiHelper(`admin/update-member`, 'PUT', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: err.message };
    }
}

export const deleteMember = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const { user_id, role } = data;

        let formData = new FormData();
        formData.append('user_id', user_id)
        formData.append('role', role)

        const response = await apiHelper(`admin/delete-user`, 'POST', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: err.message };
    }
}

export const enableMember = async (user_id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        let formData = new FormData();
        formData.append('user_id', user_id)

        const response = await apiHelper(`admin/enable-user`, 'POST', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: err.message };
    }
}

export const disableMember = async (user_id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        let formData = new FormData();
        formData.append('user_id', user_id)

        const response = await apiHelper(`admin/disable-user`, 'POST', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: err.message };
    }
}