import React, { useState, useEffect } from 'react';
import {
  getMemberDocuments,
  restoreDocuments,
} from '../../../controller/customers.controller';
import { notifyError, notifySuccess } from '../../../helper/Alert';
// import Button from '../../common/Button';
import SelectMember from './SelectMember';
// import folderIcon from '../../../assets/folder.svg';
import moment from 'moment';

export default function RestoreDocuments(props) {
  const [user_id, setUser_id] = useState(0);
  const [org_id, setOrg_id] = useState(0);
  const [selectedMember, setSelectedMember] = useState(null);
  const [memberList, setMemberList] = useState([]);
  const [docs, setDocs] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [folderCheckedList, setFolderCheckedList] = useState([]);
  const [hasRes, setHasRes] = useState(false);

  const handleSelectMember = (member) => {
    // console.log(member);
    // if (
    //   typeof member === 'object' &&
    //   member !== null &&
    //   Object.keys(member).length
    // ) {
    setSelectedMember(member);
    // }
  };

  const changeCheckedList = ({
    fileId = undefined,
    folderId = undefined,
  }) => () => {
    if (fileId) {
      if (checkedList.includes(fileId)) {
        setCheckedList(checkedList.filter((f) => f !== fileId));
      } else {
        setCheckedList([...checkedList, fileId]);
      }
    } else if (folderId) {
      if (folderCheckedList.includes(folderId)) {
        setFolderCheckedList(folderCheckedList.filter((f) => f !== folderId));
      } else {
        setFolderCheckedList([...folderCheckedList, folderId]);
      }
    }
  };
  const handleCheckAll = () => {
    if (checkedList.length + folderCheckedList.length === docs.length) {
      setCheckedList([]);
      setFolderCheckedList([]);
    } else {
      const arr = [];
      const arr2 = [];
      docs.forEach((d) => {
        if (d.file_id) {
          arr.push(d.file_id);
        } else if (d.folder_id) {
          arr2.push(d.folder_id);
        }
      });
      setCheckedList(arr);
      setFolderCheckedList(arr2);
    }
  };
  const findDocuments = async () => {
    if (selectedMember && user_id > 0 && org_id > 0) {
      const res = await getMemberDocuments({
        member_id: selectedMember.user_id,
        user_id: user_id,
        org_id: org_id
      });
      // console.log(res);
      if (res.status) {
        setDocs(res.data);
        setHasRes(true);
        notifySuccess(res.message);
      } else {
        setDocs(res.status);
        setHasRes(false);
        notifyError(res.message)
      }
    } else {
      notifyError('Please select at least one member')
    }
  };
  const handleCancel = () => {
    setSelectedMember(null);
    setHasRes(false);
    setDocs([]);
    setCheckedList([]);
    setFolderCheckedList([]);
  };
  const handleRestore = async () => {
    // console.log(checkedList)
    const res = await restoreDocuments({
      member_id: selectedMember.user_id,
      file_ids: checkedList,
      folder_ids: folderCheckedList,
      user_id:user_id, 
      org_id:org_id
    });
    if (res.status) {
      setDocs(docs.filter((f) => !checkedList.includes(f.file_id) && !folderCheckedList.includes(f.folder_id)));
      setCheckedList([]);
      setFolderCheckedList([]);
      notifySuccess(res.message);
    } else {
      notifyError(res.message)
    }
  };

  useEffect(() => {
    setMemberList(props.memberList)
    // console.log(props.memberList);
    setUser_id(props.user_id);
    setOrg_id(props.org_id);
  }, [props.memberList, props.org_id, props.user_id])

  return (
    <div className="mt-4 p-5 bg-white" style={{ borderRadius: 10 }}>
      {!hasRes && (
        <>
          Restore the documents of the user that were deleted.
          <div className="row mt-4">
            <div className="col-5">
              <SelectMember
                data={memberList}
                callback={handleSelectMember}
                placeholder=""
              />
            </div>
            <div className="col-2">
              <button type="button" className="btn btn-primary" onClick={findDocuments}>Submit</button>
            </div>
          </div>
        </>
      )}
      {hasRes && (
        <div className="row mt-4 ai-center ml-3">
          <div className="d-flex">
            Owner: {selectedMember?.email}
            <button type="button" className="btn btn-primary"
              style={{ marginLeft: '2rem' }}
              disabled={!checkedList.length && !folderCheckedList.length}
              onClick={handleRestore}
            >
              Restore Documents
            </button>
            <button type="button" className="btn btn-outline-dark"
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid black',
                marginLeft: '1rem',
              }}
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <div className="" style={{ marginTop: '4rem' }}>
        {docs.length ? (
          <table
            className="ml-3 table"
            cellPadding="0"
            cellSpacing="0"
            border="0"
            style={{ width: 'calc(100% - 2rem)' }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: '1%',
                    paddingLeft: '2rem',
                    paddingRight: '4rem',
                    textAlign: 'center',
                  }}
                >
                  <input
                    type="checkbox"
                    checked={
                      checkedList.length + folderCheckedList.length ===
                      docs.length
                    }
                    onChange={handleCheckAll}
                  />
                </th>
                <th style={{ width: '50%', textAlign: 'left' }}>Name</th>
                <th style={{ width: '20%', textAlign: 'center' }}>
                  Created Time
                </th>
                <th style={{ width: '20%', textAlign: 'center' }}>
                  Modified Time
                </th>
              </tr>
            </thead>
            <tbody>
              {docs.map((f) => (
                <tr key={f.file_id || f.folder_id}>
                  <td style={{ paddingLeft: '2rem', paddingRight: '4rem' }}>
                    <input
                      type="checkbox"
                      checked={
                        checkedList.includes(f.file_id) ||
                        folderCheckedList.includes(f.folder_id)
                      }
                      onChange={changeCheckedList({
                        fileId: f.file_id,
                        folderId: f.folder_id,
                      })}
                    />
                  </td>
                  <td>
                    {f.folder_name
                      // && <img alt="" src={folderIcon} style={{ height: '1.2rem', verticalAlign: 'middle', marginRight: '1rem' }}/>
                    }
                    {f.new_name || f.folder_name}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {moment(f.created_at).format('MMM DD, YYYY')}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {moment(f.updated_at).format('MMM DD, YYYY')}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    </div>
  );
}

