import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom';
// Switch, Route,
import Governance from './Governance';
import Reports from './Reports';

class CustomerDetails extends Component {

    constructor(props){
        super(props);
        this.state={
            org_id:0
        }
    }

    componentDidMount(){
        const { location: { search } } = this.props;
        const query = new URLSearchParams(search);
        const org_id = query.get('org_id');
        if(org_id){
            this.setState({
                org_id:org_id
            })
        }
    }

    render() {
        // const url = this.props.match.url;
        const { location: { search }, match: { params: { details } }  } = this.props;

        const query = new URLSearchParams(search);
        const org_id = query.get('org_id');
        const user_id = query.get('user_id');
        // console.log(this.props);
        const subRouter = [
            { path: `/admin/customer/reports?user_id=${user_id}&org_id=${org_id}`, name:'Reports' },
            { path: `/admin/customer/governance?user_id=${user_id}&org_id=${org_id}`, name: 'Governance' },
        ]
        return (
            <>
                <div className="container-fluid p-0">
                    <div className="row">
                        <ul className="sub-navigation">
                            {subRouter.map((nav, i) =>
                                <li key={i}><NavLink to={nav.path} activeClassName="active">{nav.name}</NavLink></li>
                            )}
                            {/* <li><NavLink to={`${url}/governance`} activeClassName="active">Governance</NavLink></li> */}
                        </ul>
                    </div>
                </div>
                {details === "reports"?<Reports org_id={org_id} user_id={user_id} />:null}
                {details === "governance"?<Governance org_id={org_id} user_id={user_id} />:null}
                {/* <Switch>
                    {subRouter.map((route, i) => <Route key={i} path={route.path} render={()=><route.component org_id={this.state.org_id} />} />)}
                </Switch> */}
            </>
        )
    }
}

export default withRouter(connect(null)(CustomerDetails))
