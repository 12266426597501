import React, { Component } from 'react';
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom';
import { adminRoutes } from '../routes';

export default class TheContent extends Component {
    render() {
        return (
            <main className="content">
                <Switch>
                    <Redirect exact from="/" to="/admin/dashboard" />
                    {adminRoutes.map((route, idx) => {
                        // console.log(route);
                        return (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                // component={<route.component />}
                                render={props => (
                                    <route.component {...props} />
                                )}
                            />
                        )
                    })}
                    <Redirect from="/" to="/admin/dashboard" />
                </Switch>
            </main>
        )
    }
}
