export const nameValidation = (value) => {
    if (value && value !== '') {
        return { status: true, message: "" }
    } else {
        return { status: false, message: "Required" }
    }
}

export const emailValidation = (value) => {
    const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (value && value !== '') {
        if (reg.test(value)) {
            return { status: true, message: "" }
        } else {
            return { status: false, message: "Invalid email" }
        }
    } else {
        return { status: false, message: "Required" }
    }
}

export const confirmPasswordValidation = (confirmPassword, newPassword) => {
    if (confirmPassword && confirmPassword !== '') {
        if (newPassword && newPassword !== '') {
            if (newPassword === confirmPassword) {
                return { status: true, message: "" }
            } else {
                return { status: false, message: "Password not match" }
            }
        } else {
            return { status: false, message: "New Password Required" }
        }
    } else {
        return { status: false, message: "Required" }
    }
}