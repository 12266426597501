import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import localStorage from 'local-storage';
import { jwtDecode } from '../helper/jwt.helper';

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.get('adminToken');
    let tokenDecode = null;
    if(token){
        tokenDecode = jwtDecode(token);
    }else{
        tokenDecode = null
    }
    return <Route
        {...rest}
        render={props => {
            return tokenDecode && tokenDecode.email ?
                <Component {...props} />
                :
                <Redirect to="/admin" />
        }}
    />
}

export default AdminPrivateRoute;
