import { apiHelper } from "../helper/api.helper";

export const adminLogin = async (data) => {
    try {

        const { email, password } = data;

        let formData = new FormData();
        formData.append('email', email)
        formData.append('password', password)

        const response = await apiHelper('admin/auth/login', 'POST', formData, null);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }

        return { status: true, data: res.data, message: res.status }
    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

export const adminChangePassword = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const { currentPassword, newPassword, confirmPassword } = data;

        let formData = new FormData();
        formData.append('oldPassword', currentPassword)
        formData.append('newPassword', newPassword)
        formData.append('reEnterNewPassword', confirmPassword)

        const response = await apiHelper('admin/profile/password/update', 'PUT', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }

        return { status: true, data: [], message: res.message }

    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}

export const updateProfile = async (data) => {
    try {

        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const { fname, lname, email } = data;

        let formData = new FormData();
        formData.append('firstName', fname)
        formData.append('lastName', lname)
        formData.append('email', email)

        const response = await apiHelper('admin/profile/update', 'PUT', formData, header);
        const res = response.data;
        // console.log(res);
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }

        return { status: true, data: res.data, message: res.message }
    } catch (err) {
        return { status: false, data: [], message: err.message }
    }
}