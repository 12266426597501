import React from 'react';
// import { useSelector } from 'react-redux';
import { MBToSize } from '../../../helper/string';

export default function UsageStackedChart(props) {
  // const [isLoading, setIsLoading] = useState(false)
  const analysis = props.fileAnalysis;
  return (
    <div
      style={{
        // background: 'white',
        marginTop: '1rem',
        textAlign: 'center',
        position: 'relative',
        width: '100%',
        height: 500,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '10rem',
      }}
    >
      <span
        style={{
          fontWeight: 'bold',
          fontSize: '1.25rem',
          position: 'absolute',
          zIndex: 99,
          left: 0,
          right: 0,
          top: '2%',
        }}
      >
        Summary details
      </span>
      <div style={{width: '80%'}}>
        {!analysis.isLoading && (
          <div style={{ width: '100%', display: 'flex' }}>
            Usage&nbsp;
            <div
              style={{
                height: '35px',
                border: '1px solid black',
                marginLeft: '1rem',
                marginRight: '1rem',
                position:'relative',
                flex: 1,
              }}
            >
              <div
                style={{
                  width: `${MBToSize(
                    Math.round(
                      analysis?.data?.usage?.count_storage_used /
                      analysis?.data?.usage?.count_storage
                    )
                  )}%`,
                  backgroundColor: 'grey',
                  position: 'absolute',
                  top: '0',
                  // right: '0',
                  bottom: '0',
                  left: '0'
                }}
              />
            </div>
            <div>
              <div>{analysis?.data?.usage ? MBToSize(analysis?.data?.usage?.count_storage) : 0}</div>
              <div>
                {analysis?.data?.usage ? MBToSize(
                  Math.round(
                    analysis?.data?.usage?.count_storage_used /
                    analysis?.data?.usage?.count_storage
                  )
                ) : 0}
                %
              </div>
            </div>
          </div>
        )}
        {!analysis.isLoading && (
          <div style={{ width: '100%', margin: '25px auto 0', display: 'flex' }}>
            <div className="d-flex col justify-content-center">
              <div style={{ width: '15px', height: '15px', border: '1px solid black', backgroundColor: 'gray', marginTop: '.2rem', marginRight: '.5rem' }} />
              <div><div>Used Space</div><div>{analysis?.data?.usage ? MBToSize(analysis?.data?.usage?.count_storage_used.toFixed(2)) : 0}</div></div>
            </div>
            <div className="d-flex col justify-content-center">
              <div style={{ width: '15px', height: '15px', border: '1px solid black', backgroundColor: 'white', marginTop: '.2rem', marginRight: '.5rem' }} />
              <div><div>Free Space</div><div>{analysis?.data?.usage ? MBToSize(Math.round(analysis?.data?.usage?.count_storage - analysis?.data?.usage?.count_storage_used)) : 0}</div></div>
            </div>
            <div className="d-flex col justify-content-center">
              <div>
                <div>Total Space</div>
                <div>{analysis?.data?.usage ? MBToSize(analysis?.data?.usage?.count_storage) : 0}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="d-block mt-auto w-100" style={{ width: '100%', display: 'block' }}>
        <div className="row mt-auto me-0 ms-0" style={{ background: '#FBFBFB' }}>
          <div className="col-sm-3 p-3 pl-4 pr-4" style={{ fontSize: '1rem', border: '1px solid rgba(0, 0, 0, 0.1)', borderRight: 'none' }}>
            <div style={{ fontSize: '1.25rem' }}>
              {analysis?.data?.usage?.count_users}
            </div>
            Users
          </div>
          <div className="col-sm-3 p-3 pl-4 pr-4" style={{ fontSize: '1rem', border: '1px solid rgba(0, 0, 0, 0.1)', borderRight: 'none' }}>
            <div style={{ fontSize: '1.25rem' }}>
              {analysis?.data?.usage?.count_shared_file}
            </div>
            Shared Documents
          </div>
          <div className="col-sm-3 p-3 pl-4 pr-4" style={{ fontSize: '1rem', border: '1px solid rgba(0, 0, 0, 0.1)', borderRight: 'none' }}>
            <div style={{ fontSize: '1.25rem' }}>
              {analysis?.data?.usage?.count_files}
            </div>
            Documents
          </div>
          <div className="col-sm-3 p-3 pl-4 pr-4" style={{ fontSize: '1rem', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
            <div style={{ fontSize: '1.25rem' }}>
              {analysis?.data?.usage?.count_folders}
            </div>
            Folders
          </div>
        </div>
      </div>
    </div>
  );
}
