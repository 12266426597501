import { combineReducers } from 'redux';
import categoriesAdminReducers from './admin/categoriesReducers';
import layoutReducers from './admin/layoutReducers';
import questionReducers from './admin/questionReducer';

export const rootReducers = combineReducers({
    // admin
    layout: layoutReducers,
    questions: questionReducers,
    categoriesAdmin: categoriesAdminReducers
});