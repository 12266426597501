import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { memberList } from '../../../controller/customers.controller';
import { jwtDecode } from '../../../helper/jwt.helper';
import RestoreDocuments from './RestoreDocuments';

class Governance extends Component {

    _unmounted = false;

    constructor(props){
        super(props);
        this.state={
            org_id:0,
            memberList:[],
            user_id:0
        }
    }

    componentDidMount(){
        this._unmounted = true;
        // console.log(this.props);
        const { org_id, user_id  } = this.props;
        const token = localStorage.getItem('adminToken');
        if(token){
            const decode = jwtDecode(token);
            // console.log(decode);
            if(decode){
                if(this._unmounted){
                    this.setState({
                        org_id,
                        user_id:user_id
                    })
                }
            }
        }
        this.getMemberList(org_id);
    }

    componentWillUnmount(){
        this._unmounted = false;
    }

    getMemberList = async (org_id) =>{
        const res = await memberList(org_id);
        // console.log(res);
        if(res.status){
            if(this._unmounted){
                this.setState({
                    memberList:res.data.organization_members
                })
            }
        }else{
            if(this._unmounted){
                this.setState({
                    memberList:[]
                })
            }
        }
    }

    render() {
        return (
            <div className="container-fluid p-0">
               <RestoreDocuments memberList={this.state.memberList} user_id={this.props.user_id} org_id={this.props.org_id} />
            </div>
        )
    }
}

export default withRouter(connect(null)(Governance))
