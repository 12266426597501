import AdminLoginComponent from './views/login';
import Dashboard from './views/dashboard'
import UsersList from './views/users'
import Customers from './views/customers';
import CustomerDetails from './views/customerDetails';
import AdminProfile from './views/profile';
import Page404 from './views/404';

export const adminRoutes = [
    { path: '/admin', component: AdminLoginComponent, exact: true, isPrivate: false, admin: false },
    { path: '/admin/dashboard', component: Dashboard, exact: true, isPrivate: true },
    { path: '/admin/users', component: UsersList, exact: true, isPrivate: true },
    { path: '/admin/profile', component: AdminProfile, exact: true, isPrivate: true },
    { path: '/admin/customers', component: Customers, exact: true, isPrivate: true },
    { path: '/admin/customer/:details', component: CustomerDetails, exact: false, isPrivate: true },
    { path: '**', component: Page404, exact: true, isPrivate: true },
]