import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

class SimpleTable extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            listData: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        // console.log(props);
        // if (this._unmounted) {
        state.listData = props.data
        // this.setState({
        //     listData: props.data
        // })
        // }

        return null;
    }

    // shouldComponentUpdate(nextProps, nextState){
    //     this.setState({
    //         listData:nextpro
    //     })
    //     return false;
    // }

    customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    componentDidMount() {
        this._unmounted = true;
        if (this._unmounted) {
            this.setState({
                listData: this.props.data
            })
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    // componentDidUpdate(prevProps){
    //     console.log(prevProps);
    // }

    render() {

        const { columns, data, keyField, noDataIndication, wrapperClasses, classes } = this.props;
        // const { listData } = this.state;
        // console.log(data);
        const paginationOptions = {
            custom: true,
            pageStartIndex: 1,
            totalSize: data.length,
            sizePerPage: 10,
            showTotal: true,
            paginationTotalRenderer: this.customTotal,
            disablePageTitle: false,
            // onPageChange: onPageChange,
            // onSizePerPageChange: onSizePerPageChange,
            // nextPageText:'',
            // prePageText:<FiChevronLeft />,
            // lastPageText:<FiChevronsRight />,
            // firstPageText:<FiChevronsLeft />
            // nextPageText:<FiChevronRight />,
            // prePageText:<FiChevronLeft />,
            // lastPageText:<FiChevronsRight />,
            // firstPageText:<FiChevronsLeft />
        };

        return (
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                wrapperClasses={wrapperClasses}
                                classes={classes}
                                bordered={false}
                                keyField={keyField}
                                columns={columns}
                                {...paginationTableProps}
                                data={data}
                                noDataIndication={noDataIndication}
                            />
                            <div className="table-wrap-footer">
                                <PaginationTotalStandalone {...paginationProps} />
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        </>
                    )
                }
            </PaginationProvider>
        )
    }
}

export default SimpleTable
