import React, { useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
const Option = (props) => (
  <components.Option {...props}>
    <div className="d-flex ai-center" style={{cursor:'pointer'}}>
      {props.data.avatar_url ? (
        <img
          alt=""
          src={props.data.avatar_url}
          style={{
            width: '2.5rem',
            height: '2.5rem',
            marginRight: '1rem',
            borderRadius:'50%'
          }}
        />
      ) : (
        <div
          style={{
            width: '2.5rem',
            height: '2.5rem',
            marginRight: '1rem',
            borderRadius: '50%',
            backgroundColor: '#ff7272',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.data.first_name[0]} {props.data.last_name[0]}
        </div>
      )}
      <div>
        <div>
          {props.data.first_name} {props.data.last_name}
        </div>
        {props.data?.email && <div>{props.data?.email}</div>}
      </div>
    </div>
  </components.Option>
);
Option.propTypes = {
  data: PropTypes.object.isRequired,
};

const Control = (props) => <components.Control {...props}/>;

const ClearIndicator = (props) =>
  <components.ClearIndicator className="clear-button" {...props}/>;
export default function SelectMember({ data, callback, placeholder, clear }) {
  const handleChange = (value) => {
    callback(value);
  };
  const selectInputRef = useRef();

  useEffect(() => {
    if (!clear) {
      selectInputRef.current.select.clearValue();
    }
  }, [clear]);

  return (
    <Select
      ref={selectInputRef}
      closeMenuOnSelect
      components={{ Option, Control, ClearIndicator }}
      onChange={(value) => handleChange(value)}
      placeholder={placeholder}
      isClearable
      setValue
      styles={{
        control: (base, { isFocused, hasValue }) => ({
          ...base,
          border: 'none',
          borderRadius: 'none',
          borderBottom:
            isFocused || hasValue ? '2px solid #534cdd ' : '2px solid #cccccc',
          height: '100%',
          boxShadow: 'none',
          '&:hover': {
            borderColor: '#534cdd ',
            borderWidth: '2px',
          },
        }),
        container: (base) => ({
          ...base,
          width: '100%',
        }),
      }}
      options={data}
      getOptionValue={(opt) => opt.user_id}
      getOptionLabel={(opt) => `${opt.first_name} ${opt.last_name}`}
    />
  );
}
SelectMember.propTypes = {
  data: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};
