// import { apiHelper } from "../helper/api.helper";
import { jwtDecode } from "../helper/jwt.helper";
import axios from 'axios';
import moment from 'moment'

const url = `https://cms.docquik.io:4000`;

export const getPlanAnalysis = async ({ startDate, endDate }) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const start = moment(startDate).format('YYYY-MM-DD HH:mm:ss')
        const end = moment(endDate).format('YYYY-MM-DD HH:mm:ss')
        // const response = await apiHelper(`admin/file-analysis?bydate=${bydate}&all=${all}&time=${time}&unit_of_time=${unit_of_time}`, 'GET', null, header);
        const response = await axios({
            method: 'GET',
            url: `${url}/admin/chart/${start}/${end}`,
            headers: header,
        });

        const res = response.data;
        // console.log(res);
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };

    } catch (err) {
        return { status: false, message: "Something went wrong." };
    }
}