import { apiHelper } from "../helper/api.helper";
import { jwtDecode } from "../helper/jwt.helper";

export const getAdminUsers = async () => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }
        const response = await apiHelper('admin/users/get', 'GET', null, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: jwtDecode(res.data), message: res.message };
    } catch (err) {
        return { status: false, message: "Something went wrong." };
    }
}

export const addSuperAdmin = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const { firstName, lastName, email, password, status } = data;

        let formData = new FormData();

        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('status', status ? 1 : 0);

        const response = await apiHelper('admin/users/add', 'POST', formData, header);
        // console.log(response);
        const res = response.data;

        if (!res.status) {
            return { status: false, message: res.message };
        }

        return { status: true, message: res.message };

    } catch (e) {
        return { status: false, message: "Something went wrong." };
    }
}
export const updateSuperAdmin = async (data) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const { id, firstName, lastName, email, password, status } = data;

        let formData = new FormData();

        formData.append('id', id);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('status', status ? 1 : 0);

        const response = await apiHelper('admin/users/update', 'PUT', formData, header);
        const res = response.data;
        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: [], message: res.message };
    } catch (e) {
        return { status: false, message: "Something went wrong." };
    }
}
export const deleteSuperAdmin = async (id) => {
    try {
        const header = {
            'Authorization': localStorage.getItem('adminToken')
        }

        const response = await apiHelper(`admin/users/delete/${id}`, 'DELETE', null, header);
        const res = response.data;

        if (!res.status) {
            return { status: false, data: [], message: res.message };
        }
        return { status: true, data: [], message: res.message };
    } catch (e) {
        return { status: false, message: "Something went wrong." };
    }
}