import React from 'react';
import { Switch, Route, withRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import AdminPrivateRoute from './components/AdminPrivateRoute';
import { adminRoutes } from './routes';
import TheLayout from './components/TheLayout';
import { connect } from 'react-redux';
import { Component } from 'react';

class App extends Component {
  render() {
    return (
      <>
        <Switch>
          {
            adminRoutes.length > 0 ?
              adminRoutes.map((route, idx) => {
                if (route.isPrivate) {
           
                  return <AdminPrivateRoute exact={route.exact} path={route.path} key={idx} component={props=><TheLayout {...props}><route.component /></TheLayout>} />
                  

                }
                else {
                  return <Route path={route.path} key={idx} exact component={route.component} />
                }
              })
              :
              null
          }

          {/* <Route path="/admin/dashboard" name="Dashboard" component={props => <TheLayout {...props} />} /> */}

        </Switch>
        <ToastContainer />
      </>
    );
  }
}

export default withRouter(connect(null)(App));
