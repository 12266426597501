import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DataTable from '../../components/DataTable';
import { getAdminUsers, deleteSuperAdmin } from '../../controller/users.controller';
import { FiEdit2, FiTrash } from 'react-icons/fi';
import AddSuperAdminModal from './AddSuperAdminModal';
import UpdateSuperAdminModal from './UpdateSuperAdminModal';
import { notifyError, notifySuccess } from '../../helper/Alert';


class UsersList extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            apiReload: false,
            userList: [],
            userData: null,
            addSuperAdminModal: false,
            isUpdateAdminModal: false
        }
    }

    componentDidMount() {
        this._unmounted = true;
        window.scrollTo(0, 0);
        this.getUsersList();
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { apiReload } = this.state;
        if (apiReload !== prevState.apiReload) {
            if (apiReload) {
                this.getUsersList();
            }
        }
    }

    reloadApi = (bool) => {
        if (this._unmounted) {
            this.setState({
                apiReload: bool,
            })
        }
    }

    getUsersList = async () => {
        const res = await getAdminUsers();
        if (res.status) {
            if (this._unmounted) {
                this.setState({
                    userList: res.data
                })
            }
            this.reloadApi(false)
        } else {
            if (this._unmounted) {
                this.setState({
                    userList: []
                })
            }
            this.reloadApi(false)
        }
    }

    emptyRender = () => {
        return <div className="empty-table">
            <p>Table is Empty</p>
        </div>
    }
    actionFormatter = (cell, row) => {
        return <div className="d-flex justify-content-center">
            <button type="button" className="border-0 bg-transparent" style={{ cursor: 'pointer' }} onClick={() => this.openModalUserUpdate(row)}><FiEdit2 /></button>
            <button type="button" className="border-0 bg-transparent text-danger" style={{ cursor: 'pointer' }} onClick={() => this.deleteUser(row)}><FiTrash /></button>
        </div>
    }
    headerCenterFormatter = (column) => {
        return <div className="d-flex justify-content-center">{column.text}</div>
    }

    addAdminModal = () => {
        if (this._unmounted) {
            this.setState({
                addSuperAdminModal: true
            })
        }
    }

    closeAdminModal = () => {
        if (this._unmounted) {
            this.setState({
                addSuperAdminModal: false
            })
        }
    }

    openModalUserUpdate = (row) => {
        // console.log(row);
        if (this._unmounted) {
            this.setState({
                userData: row,
                isUpdateAdminModal: true
            })
        }
    }

    closeUpdateAdminModal = () => {
        if (this._unmounted) {
            this.setState({
                userData: null,
                isUpdateAdminModal: false
            })
        }
    }

    deleteUser = async (row) => {
        // console.log(row);
        const res =  await deleteSuperAdmin(row.id)
        if (res.status) {
            let list = this.state.userList;
            if (Array.isArray(list) && list.length > 0) {
                list = list.filter(info => parseInt(info.id) !== parseInt(row.id))
            }
            if (this._unmounted) {
                this.setState({
                    userList: list
                })
            }
            notifySuccess(res.message);
        }else{
            notifyError(res.message);
        }
    }

    render() {

        const columns = [
            {
                dataField: 'firstName',
                text: 'First Name',
                sort: true
            },
            {
                dataField: 'lastName',
                text: 'Last Name',
                sort: true
            },
            {
                dataField: 'email',
                text: 'Email',
                sort: true
            },
            {
                dataField: 'action',
                text: 'Action',
                sort: false,
                formatter: this.actionFormatter,
                headerFormatter: this.headerCenterFormatter
            }
        ];

        return (
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3 d-flex align-items-start justify-content-between">
                    <strong>Super Admin List</strong>
                    <button type="button" className="btn btn-dark" onClick={this.addAdminModal}>Add Super Admin</button>
                </h1>
                <div className="row">
                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                        <div className="card flex-fill">
                            <DataTable
                                keyField="id"
                                data={this.state.userList}
                                columns={columns}
                                noDataIndication={this.emptyRender}
                            />
                        </div>
                    </div>
                </div>
                {this.state.addSuperAdminModal ? <AddSuperAdminModal show={this.state.addSuperAdminModal} onHide={this.closeAdminModal} reloadApi={this.reloadApi} /> : null}
                {this.state.isUpdateAdminModal ? <UpdateSuperAdminModal userData={this.state.userData} show={this.state.isUpdateAdminModal} reloadApi={this.reloadApi} onHide={this.closeUpdateAdminModal} /> : null}
            </div>
        )
    }
}

export default withRouter(connect(null)(UsersList))
